// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// Live
// const firebaseConfig = {
//   apiKey: "AIzaSyAf7D49P9UkuoGWiV4KWqWMYT8RCIXL1SM",
//   authDomain: "fw-hb-kfz.firebaseapp.com",
//   projectId: "fw-hb-kfz",
//   storageBucket: "fw-hb-kfz.appspot.com",
//   messagingSenderId: "88082030793",
//   appId: "1:88082030793:web:2e2bbf3c99f131b0e8ed18",
// };

// Test 1
const firebaseConfig = {
  apiKey: "AIzaSyDZBBFGSnucx--kfK8T9hEZ82noQWf9rBs",
  authDomain: "fw-test-30a4f.firebaseapp.com",
  projectId: "fw-test-30a4f",
  storageBucket: "fw-test-30a4f.appspot.com",
  messagingSenderId: "11439672770",
  appId: "1:11439672770:web:df33412d9ca99d707cda5b",
};

// Test 2
// const firebaseConfig = {
//   apiKey: "AIzaSyC-PT3shS12i_BqaWKQY6PBzvXuuoaLE9s",
//   authDomain: "fw-test2-214b4.firebaseapp.com",
//   projectId: "fw-test2-214b4",
//   storageBucket: "fw-test2-214b4.appspot.com",
//   messagingSenderId: "308185664194",
//   appId: "1:308185664194:web:d4159cc966861e7565095b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (email, password) => {
  let result = true;
  try {
    await signInWithEmailAndPassword(auth, email, password).catch((error) => {
      // console.log("Fehler Login");
      result = false;
    });
  } catch (err) {
    // console.error(err);
    return 1;
  }
  return result;
};

const logout = () => {
  signOut(auth);
};

export { db, auth, logInWithEmailAndPassword, logout };
