import React from "react";
import moment from "moment";

import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DisplayBox = (props) => {
  let classes = "displayBox";
  if (props.powerload) {
    classes += " powerload";
  }

  let classNeedDes = "";
  if (props.desDate * 1000 < Date.now() - 7 * 24 * 60 * 60 * 1000) {
    classNeedDes = "needDes";
  }

  return (
    <Card
      className={classes}
      draggable
      onDragStart={(e) => props.dragStart(e, props.id, props.column)}
      onDragEnd={(e) => props.dragEnd(e, props.id, props.column)}
      onDoubleClick={(e) => props.handleOpenModal(props.id)}
      style={{ overflow: "hidden" }}
    >
      <Row>
        <Col
          className="funkkennung"
          style={{
            backgroundColor: "#dfdfdf",
            borderBottom: "1px solid lightgray",
            marginBottom: "5px",
          }}
        >
          {props.funkkennung}
        </Col>
      </Row>
      <Row>
        <Col className="nutzer">{props.nutzer}</Col>
      </Row>
      <Row>
        <Col className="kennzeichen">
          <Nav.Link
            href="#"
            onClick={() => {
              props.gotoFahrzeugPage(props.kennzeichen);
            }}
          >
            {props.kennzeichen}
          </Nav.Link>
        </Col>
      </Row>
      <Row>
        <Col className="standort">{props.standort}</Col>
      </Row>
      <Row>
        <Col className="standort">
          KM:{" "}
          {props.kilometerstand && props.kilometerstand !== "" ? (
            props.kilometerstand
          ) : (
            <>Unbekannt</>
          )}
        </Col>
      </Row>
      {props.desDate ? (
        <Row>
          <Col>
            <div className="desDate">
              Des:{" "}
              <span className={classNeedDes}>
                {moment.unix(props.desDate).format("DD.MM.YYYY")}{" "}
                {classNeedDes.length > 0 ? "!" : undefined}
              </span>
            </div>
          </Col>
        </Row>
      ) : undefined}
      {props.powerload ? (
        <Row>
          <Col className="powerload-text">Powerload</Col>
        </Row>
      ) : undefined}
      <Row>
        <Col className="bemerkung">{props.bemerkung}</Col>
      </Row>

      <Card.Footer className="infoDates">
        {props.letztesUpdate ? (
          <div className="lastUpdate">Stand: {props.letztesUpdate}</div>
        ) : undefined}
      </Card.Footer>
    </Card>
  );
};

export default DisplayBox;
