import React from "react";

import Card from "react-bootstrap/Card";

import DisplayBox from "./DisplayBox";
import DisplayBoxPlaceholder from "./DisplayBoxPlaceholder";

const StatusColumn = (props) => {
  let fahrzeuge = [];

  props.data.map((fahrzeug) => {
    if (fahrzeug.status === props.column) fahrzeuge.push(fahrzeug);
  });

  let color = "black";
  if (props.color !== undefined) color = props.color;
  return (
    <Card
      className="statusColumn"
      onDragEnter={(e) => props.dragEnter(e, props.column)}
    >
      <Card.Header
        style={{ backgroundColor: props.bg, color: color, textAlign: "center" }}
      >
        {props.title}
      </Card.Header>
      <Card.Footer className="statusFooter">
        {fahrzeuge.length === 0 ? "Keine Fahrzeuge" : undefined}
        {fahrzeuge.length === 1 ? "1 Fahrzeug" : undefined}
        {fahrzeuge.length > 1 ? fahrzeuge.length + " Fahrzeuge" : undefined}
      </Card.Footer>
      <Card.Body>
        {props.dragItem.target === props.column ? (
          <DisplayBoxPlaceholder />
        ) : undefined}

        {fahrzeuge.map((item) => {
          if (item.desDate) {
            return (
              <DisplayBox
                key={item.id}
                id={item.id}
                column={props.column}
                nutzer={item.nutzer}
                funkkennung={item.funkkennung}
                bemerkung={item.bemerkung}
                kennzeichen={item.kennzeichen}
                standort={item.standort}
                bezeichnung={item.bezeichnung}
                powerload={item.powerload}
                letztesUpdate={item.letztesUpdate}
                desDate={item.desDate.seconds}
                dragStart={props.dragStart}
                dragEnd={props.dragEnd}
                handleOpenModal={props.handleOpenModal}
                kilometerstand={item.kilometerstand}
                gotoFahrzeugPage={props.gotoFahrzeugPage}
              />
            );
          }

          return (
            <DisplayBox
              key={item.id}
              id={item.id}
              column={props.column}
              nutzer={item.nutzer}
              funkkennung={item.funkkennung}
              bemerkung={item.bemerkung}
              kennzeichen={item.kennzeichen}
              standort={item.standort}
              bezeichnung={item.bezeichnung}
              powerload={item.powerload}
              letztesUpdate={item.letztesUpdate}
              dragStart={props.dragStart}
              dragEnd={props.dragEnd}
              handleOpenModal={props.handleOpenModal}
            />
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default StatusColumn;
