import React, { useState } from "react";
import { db } from "../config/firebase-config";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import moment from "moment";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MsgBox from "./MsgBox";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";

const ModalUpdateMangel = (props) => {
  const today = new Date();

  const [kategorie, setKategorie] = useState("");
  const [status, setStatus] = useState("");
  const [kontaktVorname, setKontaktVorname] = useState("");
  const [kontaktNachname, setKontaktNachname] = useState("");
  const [kontaktEmail, setKontaktEmail] = useState("");
  const [kontaktTelefon, setKontaktTelefon] = useState("");
  const [kilometer, setKilometer] = useState("");
  const [beschreibung, setBeschreibung] = useState("");

  const handleSave = () => {
    console.log("update Mangel");

    const docRef = doc(db, "fahrzeuge", "1t9HrEIjXQySk7ibi5KH");

    let newChildRef = docRef.push();

    console.log("schildRef", newChildRef);
    // let data = {
    //   new: false,
    //   status: status,
    //   datumAngelegt: today,
    //   datumLastUpdate: today,
    //   kategorie: kategorie,
    //   kontaktName: kontaktVorname + " " + kontaktNachname,
    //   kontaktEmail: kontaktEmail,
    //   kontaktTelefon: kontaktTelefon,
    //   priorität: 4,
    //   kilometer: kilometer,
    //   historie: [
    //     {
    //       beschreibung: beschreibung,
    //       nutzerkreis: "",
    //       datum: today,
    //     },
    //   ],
    // };

    // let data = {
    //   beschreibung: beschreibung,
    //   nutzerkreis: "Admins",
    //   datum: today,
    // };

    // // Atomically add a new region to the "regions" array field.
    // updateDoc(docRef, {
    //   maengel: arrayUnion(data),
    // });

    props.close();
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Fahrzeugmangel</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Status des Mangels</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="offen">Offen</option>
                      <option value="backlog">Zurückgestellt</option>
                      <option value="erledigt">Erledigt</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kategorie des Mangels</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      value={kategorie}
                      onChange={(e) => {
                        setKategorie(e.target.value);
                      }}
                    >
                      <option value="">Bitte wählen...</option>
                      <option value="1">Kategorie 1</option>
                      <option value="2">Kategorie 2</option>
                      <option value="3">Kategorie 3</option>
                      <option value="4">Kategorie 4</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Bemerkung</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ height: "150px" }}
                      value={beschreibung}
                      onChange={(e) => {
                        setBeschreibung(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Update speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUpdateMangel;
