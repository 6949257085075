import React, { useState } from "react";
import moment from "moment";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalUpdateMangel from "./ModalUpdateMangel";

const MangelBox = (props) => {
  const [showModal, setShowModal] = useState(false);

  let prio = "prio1";
  let prioText = "Hohe Priorität";

  switch (props.prio) {
    case 1:
      prio = "prio1";
      prioText = "Hohe Priorität";
      break;
    case 2:
      prio = "prio2";
      prioText = "Mittlere Priorität";
      break;
    case 3:
      prio = "prio3";
      prioText = "Niedriege Priorität";
      break;
    default:
      prio = "prio4";
      prioText = "Ohne Priorität";
      break;
  }

  const handleDoubleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <ModalUpdateMangel showModal={showModal} close={handleClose} />
      <div
        className={"mangel " + prio}
        onDoubleClick={() => {
          handleDoubleClick();
        }}
      >
        <div className="mangelHeader">
          <div className="mangelInfo">
            {props.mangel.kontaktName} | {props.mangel.kilometer} km
          </div>
          <div className="mangelPrio">{prioText}</div>
        </div>
        {props.mangel.historie.map((item) => {
          let userTmp = item.nutzerkreis;
          if (userTmp === "") userTmp = props.mangel.kontaktName;
          return (
            <div className="mangelEntry">
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  {userTmp},{" "}
                  {moment.unix(item.datum.seconds).format("DD.MM.YYYY, HH:MM")}{" "}
                  Uhr:
                </p>
                {item.beschreibung}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MangelBox;
