import React, { useState } from "react";
import {
  auth,
  logInWithEmailAndPassword,
  logout,
} from "../config/firebase-config";

import Nav from "react-bootstrap/Nav";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Copyright from "./Copyright";
import ModalMangel from "./ModalMangel";

export const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [shake, setShake] = useState(false);
  const [error, setError] = useState(false);
  const [openModalMangel, setOpenModalMangel] = useState(false);
  const [form, setForm] = useState("meldung");

  const handleSubmit = async (event) => {
    event.preventDefault();

    await logInWithEmailAndPassword(email, password).then((r) => {
      if (!r) {
        setShake(true);
        setError(true);
        setPassword("");
        setTimeout(() => {
          setShake(false);
        }, 500);
        setTimeout(() => {
          setError(false);
        }, 2500);
      }
    });
  };

  const handleOpenMeldung = () => {
    setOpenModalMangel(true);
  };

  const handleCloseMeldung = () => {
    setOpenModalMangel(false);
  };

  const handleChangePage = (page) => {
    setForm(page);
  };

  let mainClasses = "login";
  if (shake) mainClasses += " shake";

  const errorMsg = () => {
    return <div className="loginError">Benutzername oder Passwort falsch!</div>;
  };
  console.log("fahrzeuge", props.fahrzeuge);
  return (
    <>
      <ModalMangel
        showModal={openModalMangel}
        close={handleCloseMeldung}
        fahrzeuge={props.fahrezeuge}
      />
      <Card className={mainClasses}>
        <div>
          <div className="loginHeader">
            <div className="title">
              <h1>Rettungsdienst Bremen</h1>
              <p>Fahrzeugverwaltung</p>
            </div>
            <div className="logo"></div>
          </div>
          <div className="loginBorder">
            <Nav className="loginMenu">
              <Nav.Link href="#" onClick={() => handleChangePage("meldung")}>
                Meldung
              </Nav.Link>
              <Nav.Link href="#" onClick={() => handleChangePage("login")}>
                Login
              </Nav.Link>
            </Nav>
          </div>
          {form === "meldung" ? (
            <div className="loginForm">
              <Button
                onClick={() => {
                  handleOpenMeldung();
                }}
                variant="warning"
                style={{ width: "100%", marginTop: "15px" }}
              >
                Mangel an Fahrzeug melden
              </Button>
            </div>
          ) : (
            <div className="loginForm">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>E-Mail-Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="E-Mail eingeben"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Passwort</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Passwort eingeben"
                  />
                </Form.Group>
                {error ? errorMsg() : undefined}
                <Button
                  type="submit"
                  variant="primary"
                  style={{ width: "100%", marginTop: "15px" }}
                >
                  Anmelden
                </Button>
              </Form>
            </div>
          )}
          <div className="loginHinweis">
            Bei Problemen wende dich bitte an{" "}
            <a href="mailto:admin@fwhb.fleethq.de">admin@fwhb.fleethq.de</a>
          </div>
        </div>
      </Card>{" "}
      <Copyright />
    </>
  );
};

export default Login;
